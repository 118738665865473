import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { useScrollYParams } from 'hooks/useScrollYParams'
import { THEME, useTheme } from 'state/storage'
import { Initialize } from 'components/ui/initialize'
import { FeedbackButton } from 'components/ui/buttons/feedbackButton'
import { Overlay } from 'components/ui/overlay'
import { AnnouncementsContextProvider } from 'components/announcements'
import { Header } from 'components/headers'
import { Footer } from 'components/footer'
import { FloatingBanner } from 'components/ads/floatingBanner'
import { CookieBanner } from './cookieBanner'
import { FloatingBannerProvider } from 'contexts/floatingBannerContext'
import { AnnouncementBarProvider } from 'contexts/announcementBarContext'

import styles from './ui.module.scss'

// ScrollControl relies on the window scroll
const PageControl = dynamic(
    () => import('components/pageControl/').then(module => module.PageControl),
    {
        ssr: false,
    }
)

export function Ui({ children }) {
    const theme = useTheme()
    const [isAnnouncementBarVisible, setIsAnnouncementBarVisible] =
        useState(true)

    const router = useRouter()

    useScrollYParams()

    const [isSmallScreen, setIsSmallScreen] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 640)
        }

        handleResize()
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const isStakeAppPage = String(router.asPath).startsWith('/stake-app')
    const isSwapAppPage = String(router.asPath).startsWith('/swap')

    const isPortfolioDashboard = String(router.asPath).startsWith(
        '/terminal/dashboard'
    )
    const isPortfolioLandingPage =
        String(router.asPath).startsWith('/terminal') && !isPortfolioDashboard

    const withFooter =
        !isStakeAppPage &&
        !isSwapAppPage &&
        !isPortfolioLandingPage &&
        !isPortfolioDashboard

    const isBrandedStakeApp = isStakeAppPage && router?.query?.locked === 'true'
    const isBrandedSwapApp = isSwapAppPage && router?.query?.locked === 'true'

    const isBrandedPage = isBrandedStakeApp || isBrandedSwapApp

    return (
        <FloatingBannerProvider>
            <AnnouncementBarProvider>
                <div
                    id='ui'
                    className={classNames(styles.ui, {
                        [styles.srEthBg]:
                            String(router.asPath).startsWith('/sreth') ||
                            String(router.asPath).startsWith('/404'),
                        ['bg-[linear-gradient(to_bottom,#002195,#02A4FF75)]']:
                            (isStakeAppPage || isSwapAppPage) &&
                            theme === THEME.DARK,
                        'bg-[linear-gradient(to_bottom,var(--c-wb),#FEA73F00,#9C1BFA33,#02A4FF50)]':
                            (isStakeAppPage || isSwapAppPage) &&
                            theme === THEME.LIGHT &&
                            !isBrandedPage,
                        'bg-wb': isPortfolioDashboard && theme === THEME.DARK,
                        '!mt-[50px]': isAnnouncementBarVisible,
                    })}
                    data-theme={theme}
                >
                    <Initialize />
                    {children}
                    {!isSmallScreen && <FeedbackButton />}
                    {!isStakeAppPage && !isSwapAppPage && <FloatingBanner />}
                    {withFooter && <PageControl />}
                    <AnnouncementsContextProvider>
                        <Header
                            isPortfolioLandingPage={isPortfolioLandingPage}
                            isPortfolioPage={isPortfolioDashboard}
                            isStakeAppPage={isStakeAppPage || isSwapAppPage}
                            isAnnouncementBarVisible={isAnnouncementBarVisible}
                            setIsAnnouncementBarVisible={
                                setIsAnnouncementBarVisible
                            }
                        />
                        <Overlay />
                        {withFooter && <Footer />}
                    </AnnouncementsContextProvider>
                    <CookieBanner />
                </div>
            </AnnouncementBarProvider>
        </FloatingBannerProvider>
    )
}
